import type { PayStackPaymentResponse } from "@/types";
import { Payment } from "@/dto/payment/payment";
import { CreditOption } from "@/dto/payment/credit-option";
import { useFetchStateStore } from "@/store/fetch-state";

export const usePaymentStore = defineStore("payment", () => {
  const paymentOptions = ref<CreditOption[]>([]);
  const payments = ref<Payment[]>([]);
  const fetchStateStore = useFetchStateStore();

  const getPaymentOptions = async () => {
    await fetchStateStore.fetchOnce("payment-options", async () => {
      paymentOptions.value = await $api<CreditOption[]>(`${API_ROUTES.payment}/credit-options`);
    });
  };

  const getPayments = async () => {
    await fetchStateStore.fetchOnce("payments", async () => {
      payments.value = await $api<Payment[]>(API_ROUTES.payment);
    });
  };

  const makePayment = async (payment: PayStackPaymentResponse) => {
    return await $api<Payment>(API_ROUTES.payment, {
      method: "post",
      body: payment,
    });
  };

  const addPaymentToStore = (payment: Payment) => {
    if (!payment) throw createError({ statusMessage: ERROR_MESSAGES.payment_not_found });
    const index = payments.value.findIndex((p: Payment) => p.id === payment.id);
    index !== -1 ? (payments.value[index] = payment) : payments.value.unshift(payment);
  };

  return { paymentOptions, payments, getPayments, makePayment, getPaymentOptions, addPaymentToStore };
});
